export const formIds = {
    stottemedlem: 'stottemedlem',
    gave: 'gave',
    bok: 'bok',
    kalender: 'kalender',
    bilpakke: 'bilpakke',
    anledningsgave: 'anledningsgave',
    forstehjelpspakke: 'forstehjelpspakke',
    helikopter: 'helikopter',
    minnegave: 'minnegave',
    bamse: 'bamse',
    testament: 'testament',
    nyhetsbrev: 'nyhetsbrev',
    bedriftprodukt: 'bedriftprodukt',
    symbolskgave: 'symbolskgave'
};

const pushEvent = evt => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(evt);
};

export const trackViewing = formId => {
    pushEvent({
        event: `${formId}-visning`,
        pagePath: window.location.href,
        formId
    });
};
