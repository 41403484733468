import { h, render } from 'preact'; /** @jsx h */
import { formIds, trackViewing } from '../forms/trackingHelpers';
import { getKampValue } from './campaign-tracking.js';

// må settes slik at skjema JS blir lastet fra riktig URL i nettleseren
__webpack_public_path__ = '/wp-content/themes/n16e-theme/frontend/build/'; // eslint-disable-line

const renderFromDone = id => {
    if (window.location.hash === '#' + id) {
        const elemtentWithForm = document.querySelector('#' + id);

        elemtentWithForm.scrollIntoView();
    }
};

// level skal være satt som int eller så blir den 2
const parseLevel = (value, dVal = 2) => {
    if (!value || value === '') {
        return dVal;
    } else {
        value = parseInt(value);

        return isNaN(value) ? 2 : value;
    }
};

const urlParams = new URLSearchParams(window.location.search);
const kamp = getKampValue();
const phoneNoParam = urlParams.get('t');

const helicopterforms = [...document.querySelectorAll('.helicopter-form-wrapper')];
const firstAidForm = [...document.querySelectorAll('.firstaid-form-wrapper')];
const giftforms = [...document.querySelectorAll('.gift-form-wrapper')];
const teddyForms = [...document.querySelectorAll('.teddy-form-wrapper')];
const calendarForms = [...document.querySelectorAll('.calendar-form-wrapper')];
const bookForms = [...document.querySelectorAll('.book-form-wrapper')];
const memorialform = [...document.querySelectorAll('.memorial-form-wrapper')];
const eventsGiftForm = [...document.querySelectorAll('.events-gift-form-wrapper')];
const subscribeNewsletterForms = [...document.querySelectorAll('.form-abo-email')];
const subscribeProNewsletterForms = [...document.querySelectorAll('.form-abo-pro-email')];
const supportUsForms = [...document.querySelectorAll('.supportus-form-wrapper')];
const bedriftProduktForms = [...document.querySelectorAll('.form-bedrift-produkt-wrapper')];
const willInfoForms = [...document.querySelectorAll('.willinfo-form-wrapper')];
const carPackForms = [...document.querySelectorAll('.carpack-form-wrapper')];
const personSearchForms = [...document.querySelectorAll('.wp-block-snla-form-person-search')];
const symbolicGiftForm = [...document.querySelectorAll('.gift-symbolic-form-wrapper')];
const blanketForms = [...document.querySelectorAll('.blanket-form-wrapper')];
const wpforms = [...document.querySelectorAll('.wpforms-form')];
const addToCartButtons = [...document.querySelectorAll('.wp-block-snla-add-to-cart button')];

if (helicopterforms.length > 0) {
    // laster kun i HelicopterForm hvis det finnes slike elementer på siden
    import(/* webpackChunkName: "ProductForm" */ '../forms/ProductForm').then(module => {
        const ProductForm = module.default;

        helicopterforms.forEach((element, i) => {
            const key = `heli-${i + 1}`;
            const blockCode = element.getAttribute('data-kamp') || '';
            const title = element.getAttribute('data-title') || null;
            const level = parseLevel(element.getAttribute('data-level') || 2);

            // fjerner eventuelle child nodes i element
            element.innerHTML = '';

            render(
                <ProductForm
                    id={key}
                    key={key}
                    renderCb={renderFromDone}
                    kamp={kamp}
                    titleStep1={title}
                    level={level}
                    blockCode={blockCode}
                    defaultTitle="Støtt vårt arbeid og få en helikoptermodell som takk"
                    numberLabel="Hvor mange helikoptermodeller ønsker du?"
                    price="595"
                    eventFormName="helicopter"
                    createScriptUri="/medl/helikopter_create.php"
                />,
                element
            );
        });
    });

    trackViewing(formIds.helikopter);
}
if (firstAidForm.length > 0) {
    // laster kun i HelicopterForm hvis det finnes slike elementer på siden
    import(/* webpackChunkName: "ProductForm" */ '../forms/ProductForm').then(module => {
        const ProductForm = module.default;

        firstAidForm.forEach((element, i) => {
            const key = `firstaid-${i + 1}`;
            const blockCode = element.getAttribute('data-kamp') || null;
            const title = element.getAttribute('data-title') || null;
            const level = parseLevel(element.getAttribute('data-level') || 2);

            // fjerner eventuelle child nodes i element
            element.innerHTML = '';

            render(
                <ProductForm
                    id={key}
                    key={key}
                    renderCb={renderFromDone}
                    kamp={kamp}
                    titleStep1={title}
                    level={level}
                    blockCode={blockCode}
                    defaultTitle="Støtt vårt arbeid og få en førstehjelpspakke som takk"
                    numberLabel="Hvor mange førstehjelpspakker ønsker du?"
                    price="495"
                    eventFormName="firstaid"
                    createScriptUri="'/medl/hjelpepakke2_create.php'"
                />,
                element
            );
        });
    });

    trackViewing(formIds.forstehjelpspakke);
}
if (giftforms.length > 0) {
    import(/* webpackChunkName: "GiftForm" */ '../forms/GiftForm').then(module => {
        const GiftForm = module.default;

        giftforms.forEach((element, i) => {
            const key = `gift-${i + 1}`;
            const blockCode = element.getAttribute('data-kamp') || null;
            const title = element.getAttribute('data-title') || null;
            const level = parseLevel(element.getAttribute('data-level') || 2);
            const infotxt = element.getAttribute('data-infotxt') || null;
            let options = element.getAttribute('data-opts') || '';

            if (options && options !== '') {
                options = JSON.parse(`[${options}]`);
            }

            // fjerner eventuelle child nodes i element
            element.innerHTML = '';

            render(
                <GiftForm
                    id={key}
                    key={key}
                    renderCb={renderFromDone}
                    kamp={kamp}
                    phoneNo={phoneNoParam}
                    titleStep1={title}
                    level={level}
                    infoTxtStep1={infotxt}
                    blockCode={blockCode}
                    options={options}
                />,
                element
            );
        });
    });

    trackViewing(formIds.gave);
}
if (symbolicGiftForm.length > 0) {
    import(/* webpackChunkName: "SymbolicGiftForm" */ '../forms/SymbolicGiftForm').then(module => {
        const SymboligGiftForm = module.default;

        symbolicGiftForm.forEach((element, i) => {
            const key = `symbolic-gift-${i + 1}`;
            const blockCode = element.getAttribute('data-kamp') || null;
            const title = element.getAttribute('data-title') || null;
            const level = parseLevel(element.getAttribute('data-level') || 2);
            const startProduct = parseLevel(element.getAttribute('data-prodid') || 0, 0);

            // fjerner eventuelle child nodes i element
            element.innerHTML = '';

            render(
                <SymboligGiftForm
                    id={key}
                    key={key}
                    renderCb={renderFromDone}
                    kamp={kamp}
                    titleStep1={title}
                    level={level}
                    blockCode={blockCode}
                    startProduct={startProduct}
                />,
                element
            );
        });
    });

    trackViewing(formIds.symbolskgave);
}
if (teddyForms.length > 0) {
    // laster kun i HelicopterForm hvis det finnes slike elementer på siden
    import(/* webpackChunkName: "ProductForm" */ '../forms/ProductForm').then(module => {
        const ProductForm = module.default;

        teddyForms.forEach((element, i) => {
            const key = `teddy-${i + 1}`;
            const blockCode = element.getAttribute('data-kamp') || null;
            const title = element.getAttribute('data-title') || null;
            const level = parseLevel(element.getAttribute('data-level') || 2);

            // fjerner eventuelle child nodes i element
            element.innerHTML = '';

            render(
                <ProductForm
                    id={key}
                    key={key}
                    renderCb={renderFromDone}
                    kamp={kamp}
                    titleStep1={title}
                    level={level}
                    blockCode={blockCode}
                    defaultTitle="Støtt vårt arbeid og få en bamse som takk"
                    numberLabel="Hvor mange bamser ønsker du?"
                    price="460"
                    eventFormName="teddy"
                    createScriptUri="/medl/bamse_create.php"
                />,
                element
            );
        });
    });

    trackViewing(formIds.bamse);
}
if (calendarForms.length > 0) {
    // laster kun i HelicopterForm hvis det finnes slike elementer på siden
    import(/* webpackChunkName: "ProductForm" */ '../forms/ProductForm').then(module => {
        const ProductForm = module.default;

        calendarForms.forEach((element, i) => {
            const key = `calendar-${i + 1}`;
            const blockCode = element.getAttribute('data-kamp') || null;
            const title = element.getAttribute('data-title') || null;
            const level = parseLevel(element.getAttribute('data-level') || 2);

            // fjerner eventuelle child nodes i element
            element.innerHTML = '';

            render(
                <ProductForm
                    id={key}
                    key={key}
                    renderCb={renderFromDone}
                    kamp={kamp}
                    titleStep1={title}
                    level={level}
                    blockCode={blockCode}
                    defaultTitle="Støtt vårt arbeid og få en kalender som takk"
                    numberLabel="Hvor mange kalendere ønsker du?"
                    price="325"
                    eventFormName="calender"
                    createScriptUri="/medl/kalender_create.php"
                />,
                element
            );
        });
    });

    trackViewing(formIds.kalender);
}
if (bookForms.length > 0) {
    // laster kun i HelicopterForm hvis det finnes slike elementer på siden
    import(/* webpackChunkName: "ProductForm" */ '../forms/ProductForm').then(module => {
        const ProductForm = module.default;

        bookForms.forEach((element, i) => {
            const key = `book-${i + 1}`;
            const blockCode = element.getAttribute('data-kamp') || null;
            const title = element.getAttribute('data-title') || null;
            const level = parseLevel(element.getAttribute('data-level') || 2);

            // fjerner eventuelle child nodes i element
            element.innerHTML = '';

            render(
                <ProductForm
                    id={key}
                    key={key}
                    renderCb={renderFromDone}
                    kamp={kamp}
                    titleStep1={title}
                    level={level}
                    blockCode={blockCode}
                    defaultTitle="Støtt vårt arbeid og få en bok som takk"
                    numberLabel="Hvor mange bøker ønsker du?"
                    price="500"
                    eventFormName="book"
                    createScriptUri="/medl/jubileumsbok_create.php"
                />,
                element
            );
        });
    });

    trackViewing(formIds.bok);
}
if (memorialform.length > 0) {
    // laster kun i HelicopterForm hvis det finnes slike elementer på siden
    import(/* webpackChunkName: "MemorialForm" */ '../forms/MemorialForm').then(module => {
        const MemorialForm = module.default;

        memorialform.forEach((element, i) => {
            const key = `memorial-${i + 1}`;
            const blockCode = element.getAttribute('data-kamp') || null;
            let options = element.getAttribute('data-opts') || '';
            options = JSON.parse(`[${options}]`);
            const level = parseLevel(element.getAttribute('data-level'));

            render(
                <MemorialForm
                    id={key}
                    key={key}
                    renderCb={renderFromDone}
                    options={options}
                    kamp={kamp}
                    level={level}
                    blockCode={blockCode}
                />,
                element
            );
        });
    });

    trackViewing(formIds.minnegave);
}
if (eventsGiftForm.length > 0) {
    // laster kun i HelicopterForm hvis det finnes slike elementer på siden
    import(/* webpackChunkName: "EventsGiftForm" */ '../forms/EventsGiftForm').then(module => {
        const EventsGiftForm = module.default;

        eventsGiftForm.forEach((element, i) => {
            const key = `events-gift-${i + 1}`;
            const blockCode = element.getAttribute('data-kamp') || null;
            let options = element.getAttribute('data-opts') || '';
            options = JSON.parse(`[${options}]`);
            const level = parseLevel(element.getAttribute('data-level'));
            const giftEvent = element.getAttribute('data-gift-event');

            render(
                <EventsGiftForm
                    id={key}
                    key={key}
                    renderCb={renderFromDone}
                    options={options}
                    kamp={kamp}
                    level={level}
                    blockCode={blockCode}
                    giftEvent={giftEvent}
                />,
                element
            );
        });
    });

    trackViewing(formIds.anledningsgave);
}

if (supportUsForms.length > 0) {
    // laster kun i HelicopterForm hvis det finnes slike elementer på siden
    import(/* webpackChunkName: "SupportUsForm" */ '../forms/SupportUsForm').then(module => {
        const SupportUsForm = module.default;

        supportUsForms.forEach((element, i) => {
            const key = `support-us-${i + 1}`;
            const blockCode = element.getAttribute('data-kamp') || null;
            let options = element.getAttribute('data-opts') || '';
            options = JSON.parse(`[${options}]`);
            let payments = element.getAttribute('data-payment') || '';
            payments = JSON.parse(`[${payments}]`);
            const title = element.getAttribute('data-title') || null;
            const infotxt = element.getAttribute('data-infotxt') || null;
            const level = parseLevel(element.getAttribute('data-level') || 2);
            const isStudent = element.getAttribute('data-student') === 'true';

            // fjerner eventuelle child nodes i element
            element.innerHTML = '';

            render(
                <SupportUsForm
                    id={key}
                    key={key}
                    renderCb={renderFromDone}
                    options={options}
                    payments={payments}
                    kamp={kamp}
                    titleStep1={title}
                    infoTxtStep1={infotxt}
                    level={level}
                    isStudent={isStudent}
                    blockCode={blockCode}
                />,
                element
            );
        });
    });

    trackViewing(formIds.stottemedlem);
}

if (subscribeNewsletterForms.length > 0) {
    import(/* webpackChunkName: "EmailAboForm" */ '../forms/EmailAboForm').then(module => {
        const EmailAboForm = module.default;

        subscribeNewsletterForms.forEach((element, i) => {
            const key = `subscribe-form-${i + 1}`;
            const blockCode = element.getAttribute('data-kamp') || null;
            const showYes = element.getAttribute('data-show-yes') || null;

            render(
                <EmailAboForm
                    id={key}
                    key={key}
                    renderCb={renderFromDone}
                    blockCode={blockCode}
                    showYes={showYes === 'true'}
                />,
                element
            );
        });
    });

    trackViewing(formIds.nyhetsbrev);
}

if (subscribeProNewsletterForms.length > 0) {
    import(/* webpackChunkName: "EmailAboForm" */ '../forms/EmailAboForm').then(module => {
        const EmailAboForm = module.default;

        subscribeProNewsletterForms.forEach((element, i) => {
            const key = `subscribe-pro-form-${i + 1}`;
            const blockCode = element.getAttribute('data-kamp') || null;
            const showYes = element.getAttribute('data-show-yes') || null;

            render(
                <EmailAboForm
                    id={key}
                    key={key}
                    renderCb={renderFromDone}
                    blockCode={blockCode}
                    showYes={showYes === 'true'}
                    api="/medl/epost-pro-abo.php"
                    showSelect={true}
                />,
                element
            );
        });
    });

    trackViewing(formIds.nyhetsbrev);
}

if (bedriftProduktForms.length > 0) {
    // laster kun i HelicopterForm hvis det finnes slike elementer på siden
    import(/* webpackChunkName: "BedriftProduktForm" */ '../forms/BedriftProduktForm').then(
        module => {
            const BedriftProduktForm = module.default;

            bedriftProduktForms.forEach((element, i) => {
                const key = `bedrift-produkt-${i + 1}`;
                const blockCode = element.getAttribute('data-kamp') || null;
                const productCode = element.getAttribute('data-product-code') || '1';
                let options = element.getAttribute('data-opts');
                options = options && JSON.parse(`[${options}]`);

                render(
                    <BedriftProduktForm
                        id={key}
                        key={key}
                        renderCb={renderFromDone}
                        kamp={kamp}
                        blockCode={blockCode}
                        productCode={productCode}
                        options={options}
                    />,
                    element
                );
            });
        }
    );

    trackViewing(formIds.bedriftprodukt);
}

if (willInfoForms.length > 0) {
    // laster kun i HelicopterForm hvis det finnes slike elementer på siden
    import(/* webpackChunkName: "OrderWillInfoForm" */ '../forms/OrderWillInfoForm').then(
        module => {
            const OrderWillInfoForm = module.default;

            willInfoForms.forEach((element, i) => {
                const key = `order-will-info-${i + 1}`;
                const blockCode = element.getAttribute('data-kamp') || null;
                const level = parseLevel(element.getAttribute('data-level') || 2);

                render(
                    <OrderWillInfoForm
                        id={key}
                        key={key}
                        renderCb={renderFromDone}
                        kamp={kamp}
                        level={level}
                        blockCode={blockCode}
                    />,
                    element
                );
            });
        }
    );

    trackViewing(formIds.testament);
}

if (carPackForms.length > 0) {
    // laster kun i HelicopterForm hvis det finnes slike elementer på siden
    import(/* webpackChunkName: "ProductForm" */ '../forms/ProductForm').then(module => {
        const ProductForm = module.default;

        carPackForms.forEach((element, i) => {
            const key = `carpack-${i + 1}`;
            const blockCode = element.getAttribute('data-kamp') || null;
            const title = element.getAttribute('data-title') || null;
            const level = parseLevel(element.getAttribute('data-level') || 2);

            // fjerner eventuelle child nodes i element
            element.innerHTML = '';

            render(
                <ProductForm
                    id={key}
                    key={key}
                    renderCb={renderFromDone}
                    kamp={kamp}
                    titleStep1={title}
                    level={level}
                    blockCode={blockCode}
                    defaultTitle="Støtt vårt arbeid og få en bilpakke som takk"
                    numberLabel="Hvor mange bilpakker ønsker du?"
                    price="625"
                    eventFormName="carpack"
                    createScriptUri="/medl/bilpakke_create.php"
                />,
                element
            );
        });
    });

    trackViewing(formIds.bilpakke);
}

if (blanketForms.length > 0) {
    // laster kun i HelicopterForm hvis det finnes slike elementer på siden
    import(/* webpackChunkName: "ProductForm" */ '../forms/ProductForm').then(module => {
        const ProductForm = module.default;

        blanketForms.forEach((element, i) => {
            const key = `blanket-${i + 1}`;
            const blockCode = element.getAttribute('data-kamp') || null;
            const title = element.getAttribute('data-title') || null;
            const level = parseLevel(element.getAttribute('data-level') || 2);

            // fjerner eventuelle child nodes i element
            element.innerHTML = '';

            render(
                <ProductForm
                    id={key}
                    key={key}
                    renderCb={renderFromDone}
                    kamp={kamp}
                    titleStep1={title}
                    level={level}
                    blockCode={blockCode}
                    defaultTitle="Støtt vårt arbeid og få et varmeteppe som takk"
                    numberLabel="Hvor mange varmetepper ønsker du?"
                    price="595"
                    eventFormName="blanket"
                    createScriptUri="/medl/varmeteppe_create.php"
                />,
                element
            );
        });
    });

    trackViewing(formIds.bilpakke);
}

if (personSearchForms.length > 0) {
    // laster kun i PersonSearchForm hvis det finnes slike elementer på siden
    import(/* webpackChunkName: "PersonSearchForm" */ '../forms/PersonSearchForm').then(module => {
        const PersonSearchForm = module.default;

        personSearchForms.forEach((element, i) => {
            const key = `searchPerson-${i + 1}`;
            const infotext = element.getAttribute('data-infotext') || null;
            const title = element.getAttribute('data-title') || null;
            const level = parseLevel(element.getAttribute('data-level') || 2);

            // fjerner eventuelle child nodes i element
            element.innerHTML = '';

            render(
                <PersonSearchForm key={key} titleStep1={title} level={level} infotext={infotext} />,
                element
            );
        });
    });

    trackViewing(formIds.bilpakke);
}

if (wpforms.length > 0) {
    // Laster kun inn wpforms custom script hvis det finnes wpforms elementer på siden
    import('../forms/wpforms.js');
}

if (addToCartButtons.length > 0) {
    // Laster kun inn addToCartBtn custom script hvis det finnes addToCartBtn elementer på siden
    import('../forms/addToCartBtn.js');
}
